/*=======================================
			Base Settings
=======================================*/

// H1 Styles
$h1-text-color: $primary-color;
$h1-font-weight: $header-font-weight;

// H2 Styles
$h2-text-color: #000;
$h2-font-weight: 500;

// H3 Stylez
$h3-height: rem-calc(50);
$h3-padding: rem-calc(12);
$h3-border: 3px solid $primary-color;
$h3-text-color: #333;
$h3-bgcolor: #efefef;

// Image list
$images-list-margin: rem-calc(6) 0 rem-calc(6) 0;
$images-list-padding: 0 0 0 rem-calc(28);
$images-list-image: url("/images/image-list.png");
$images-list-image-height: rem-calc(22);
$images-list-text-color: #4a4f50;
$images-list-font-size: rem-calc(17);

// Picframe
$picframe-border: 0;
$picframe-shadow: 0 5px 1px -3px #666;

// Misc
$content-bgcolor: #fff;
$heading-font-family: $header-font-family;

body{
	background-color: $body-bg;
}

.wrapper {
	overflow: hidden;
}

h1{

	color: $h1-text-color;
	font-weight: $h1-font-weight;
	line-height: 1.2;
}

h2{
	color: $h2-text-color;
	font-weight: $h2-font-weight;
	line-height: 1.2;
	font-weight: 700;
	margin-bottom:rem-calc(30);
}

h3{
	padding: $h3-padding;
	margin-bottom:rem-calc(20);
	line-height: $h3-height;
	border-bottom: $h3-border;
	color: $h3-text-color;
	background-color: $h3-bgcolor;
	line-height: 1.4;
	font-weight: 500;
	text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.35);
}

h4 {
	margin:rem-calc(0 0 30 0);
	font-weight: 700;
}

.heading{
	font-family: $heading-font-family;
}

.highlight {
	color:$primary-color;
}

h2.highlight {
	margin-bottom:rem-calc(8);
	font-size:rem-calc(22);
}

// Reset list inside nav elements
nav{
	ul{
		margin: 0;
		padding: 0;
	}

	ul ul{
		margin: 0;
		padding: 0;
	}

	li{
		margin: 0;
		padding: 0;
		list-style: none;
	}
}

// Center icons
a i:before, a i:after{
	display: inline-block;
	vertical-align: middle;
	text-decoration: none;
}

.content{
	background-color: $content-bgcolor;
}

// Picframe Classes
.picframe {
	border: 1px solid #ccc;
	padding: 10px;
	background: #fff;
	margin-bottom: rem-calc(20);
}
.picframe{
	width: 100%;
	&.left{
		margin-right: rem-calc(30);
	}

	&.right{
		margin-left: rem-calc(30);
	}

	@media #{$medium-up}{
		width: auto;
	}
}

ul.fourth-columns{
	margin: rem-calc(0 0 40 0);
	padding: 0;
	list-style: none;
	@include clearfix;

	li{
		margin: $images-list-margin;
		padding: $images-list-padding;
		color: $images-list-text-color;
		font-size: $images-list-font-size;
		line-height: $images-list-image-height;
		background-image: $images-list-image;
		background-repeat: no-repeat;
		background-position: 0 50%;
		@media #{$medium-up}{
			float:left;
			width:50%;
		}
		@media #{$large-up}{
			float:left;
			width:25%;
		}
	}
}

ul.two-columns {
	margin:0;
	list-style-type: none;
	@extend .clearfix;
	li {
		padding-right: rem-calc(12);
		&:before {
			content:'-';
			margin:rem-calc(0 3 0 0);
		}
		@media #{$medium-up}{
			float: left;
			width:50%;
		}
	}
}

ul.three-columns {
	margin:0;
	list-style-type: none;
	@extend .clearfix;
	li {
		padding-right: rem-calc(20);
		&:before {
			content:'-';
		}
		@media #{$medium-up}{
			float: left;
			width:33.333%;
		}
	}
}

ul.special{
	margin: 0;
	padding: 0;
	list-style: none;

	li{
		margin: $images-list-margin;
		padding: $images-list-padding;
		color: $images-list-text-color;
		font-size: $images-list-font-size;
		line-height: $images-list-image-height;
		&:before {
			content:url('/images/image-list.png');
			margin:rem-calc(0 7 0 0);
		}
	}
}
