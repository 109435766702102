@charset "UTF-8";

@import "partials/foundation-settings";

/*=======================================
				Imports
=======================================*/

// Normalize 3.0.3
@import "vendor/normalize/normalize";

// Foundation 5.5.2
@import 'vendor/foundation/grid';
@import 'vendor/foundation/accordion';
@import 'vendor/foundation/alert-boxes';
@import 'vendor/foundation/block-grid';
// @import 'vendor/foundation/breadcrumbs';
@import 'vendor/foundation/button-groups';
@import 'vendor/foundation/buttons';
@import 'vendor/foundation/clearing';
@import 'vendor/foundation/dropdown';
@import 'vendor/foundation/dropdown-buttons';
// @import 'vendor/foundation/flex-video';
@import 'vendor/foundation/forms';
// @import 'vendor/foundation/icon-bar';
// @import 'vendor/foundation/inline-lists';
// @import 'vendor/foundation/joyride';
// @import 'vendor/foundation/keystrokes';
@import 'vendor/foundation/labels';
// @import 'vendor/foundation/magellan';
// @import 'vendor/foundation/orbit';
@import 'vendor/foundation/pagination';
@import 'vendor/foundation/panels';
// @import 'vendor/foundation/pricing-tables';
// @import 'vendor/foundation/progress-bars';
// @import 'vendor/foundation/range-slider';
// @import 'vendor/foundation/reveal';
@import 'vendor/foundation/side-nav';
// @import 'vendor/foundation/split-buttons';
// @import 'vendor/foundation/sub-nav';
// @import 'vendor/foundation/switches';
@import 'vendor/foundation/tables';
// @import 'vendor/foundation/tabs';
@import 'vendor/foundation/thumbs';
// @import 'vendor/foundation/tooltips';
@import 'vendor/foundation/top-bar';
@import 'vendor/foundation/type';
@import 'vendor/foundation/offcanvas';
@import 'vendor/foundation/visibility';

@import "partials/fonts";

// Flexslider 2.4.0
@import "vendor/flexslider/flexslider";

// Fancybox 2.1.5
@import "vendor/fancybox/jquery.fancybox";

// ImageLightBox by http://osvaldas.info/image-lightbox-responsive-touch-friendly
@import "vendor/imagelightbox/imagelightbox";

// Architecture Settings
@import "partials/hlm-mixins";

// Site Styles
@import "partials/base";
@import "partials/layouts";
@import "partials/header";
@import "partials/coupons";
@import "partials/articles";
@import "partials/blog-grid";
@import "partials/form-controls";
@import "partials/banner";
@import "partials/logos";
// @import "partials/banner-with-nav";
@import "partials/sub-banner";
@import "partials/subnav";
@import "partials/offcanvas-hlm";
@import "partials/contact-box";
@import "partials/callout-links";
// @import "partials/callout-blocks";
@import "partials/testimonials";
@import "partials/homepage-callout";
@import "partials/social-links";
@import "partials/footer-links";
@import "partials/page-home";
@import "partials/footer";
@import "partials/search";
@import "partials/callout-bar";
@import "partials/callouts.home";

/*=======================================
			Clearing Gallery
=======================================*/

.clearing-close{
	text-decoration: none;
}

.carousel{
	margin-top: 0 !important;
}

/*=======================================
			Flex Slider
=======================================*/

.slides{
	@include clearfix;
	padding:0;
	margin:0;
}

/*=======================================
			Sub Navigation
=======================================*/

.sub-nav {
	display: block;
	width: auto;
	overflow: hidden;
	margin: -0.22222rem 0 1rem;
	padding-top: 0.22222rem;
	margin-right: 0;
	margin-left: -0.66667rem;
	background-color: #f2f2f2;
	padding: 10px 10px 10px 0;
	a{
		text-decoration:none;
		padding:0 20px;
		display:block;
		float:left;
	}
	.active{
		a{
			background:#008CBA;
			color:#fff;
		}
	}
}

/*=======================================
			SML Settings
=======================================*/

.sml{
	h4{
		color: #fff;
		font-size: rem-calc(24);
	}

	ul{}

	li{
		color: #fff;
	}

	a{
		color: inherit;
	}
}

/*=======================================
			Site Styles
=======================================*/

.button{
	&.primary {
		background:linear-gradient(0deg,#0001f7 0%,#1647ff 100%);
	}

	i{
		vertical-align: top;
	}

	&.secondary {
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.27) 0%, rgba(255, 255, 255, 0.27) 100%);
		background-color: #16a646;
	}

	&.tertiary{
		color: #000;
		font-weight: 600;
		border: 1px solid #fff17e;
		text-shadow: none;
		background: #ffe942 linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
	}
}


// Featured section on home page

.featured-section {
	background:linear-gradient(90deg,#fff 0%, #fff 50%, #f5f5f5 50%, #f5f5f5 100%);
	position: relative;

	.featured-content {
		@include clearfix;
	}

	#featured-robot {
		background-color: #f5f5f5;
		border-left:1px solid #ebebeb;
		padding:rem-calc(25 0 25 0);
		@media #{$large-up}{
			padding:rem-calc(25 0 50 30);
		}
	}

	#featured-video {
		background-color: #fff;
		border-right:1px solid #d8d8d8;
		padding:rem-calc(25 20 25 0);
		@media #{$large-up}{
			padding:rem-calc(25 20 50 0);
		}
	}

	h3 {
		border:1px solid #575757;
		background-color: #868686;
		color:#fff;
		padding:rem-calc(8 12);
	}

	.featured-title {
		font-size:rem-calc(23);
		color:#000;
		font-weight: 500;
		margin-bottom:rem-calc(20);
	}

	.featured-subtitle {
		background-color: rgba(0,0,0,0);
		border:0;
		padding:0;
		margin:rem-calc(0 0 30 0);

		color:#000;
		font-size:rem-calc(24);
		font-weight: 500;
	}

	.featured-image {
		float:left;
		margin:rem-calc(0 20 20 0);
		box-shadow: 0 5px 1px -3px #666;
	}

	.featured-button.button {
		background-color: #fff;
		border:1px solid $primary-color;
		color:$primary-color;
		transition:all .3s ease;
		display: block;
		&:hover {
			background-color: $primary-color;
			color:#fff;
		}
	}

	.featured-list {
		margin-left:rem-calc(15);
		list-style: none;
		display: inline-block;
		li {
			padding-left:rem-calc(5);
			list-style-image: url('/images/image-list.png');
			color:$primary-color;
			font-weight: 700;
			font-size:rem-calc(18);
		}
	}

	.below-featured-arrow {
		position: absolute;
		bottom:-29px;
		z-index: 20;
		left:-51px;
		margin:0 auto;
	}
}

// Free Evaluation Box on homepage

.free-eval {
	background:url('/images/free-eval-bg.jpg') top center;
	background-size: cover;
	padding:rem-calc(65 0 55);
	position: relative;

	h2 {
		color:#fff;
		font-size:rem-calc(30);
		font-weight: 500;
		text-transform: uppercase;
		text-shadow: 1px 2px 0px rgba(0, 0, 0, 0.3);
	}

	p {
		margin:rem-calc(23 0 30 0);
		color:#fff;
		line-height: 1.5;
		font-size:rem-calc(24);
		font-weight: 300;
		text-shadow: 1px 2px 0px rgba(0, 0, 0, 0.3);
	}

	.button {
		border-radius: 3px;
		border:1px solid #fff;
		box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.19);
		margin:rem-calc(8 10);
		transition:all .3s ease;

		font-size:rem-calc(22);

		span {
			color:#fffbcf;
			text-transform: uppercase;
		}
	}
}

// Mobile Header Buttons

.mobile-header-buttons {
	padding:rem-calc(20 0);
	background-color: rgba(255,255,255,.45);

	a {
		background-color: #696969;
		padding:rem-calc(10);
		margin-right:rem-calc(10);
		border-radius:rem-calc(3);
		transition:all .3s ease;

		color:#fff;
		text-decoration: none;

		&:hover {
			background-color: $primary-color;
		}
	}
}

// Triangle in header

.empty-triangle {
	position: absolute;
	top:0;
	left:0;
	right:0;
	margin:0 auto;
}

// Sliding right navigation bar on homepage

.right-nav {
	display: inline-block;
	position: fixed;
	right:-170px;
	top:rem-calc(310);
	z-index: 100;
	transition:all 1s ease;

	&:hover {
		right:0px;
	}

	ul {
		list-style: none;
		margin:0;

		li {
			text-align: left;
			display: block;
			background-color: #0c121c;
			bottom-border:1px solid #353a42;
			transition:all .2s ease;

			&:hover {
				background-color: $primary-color;
			}

			img {
				margin-right:rem-calc(15);
			}

			a {
			 padding:rem-calc(10);
			 width:100%;
			 height:100%;
			 display: block;

	     text-decoration: none;
			 font-family: 'Ubuntu';
			 color:#fff;
			 font-size:rem-calc(16);
			}
		}
	}
}

// Three column UL

.three-column-list {
	@include clearfix;
	margin:0;
	list-style-type: none;
	li {
		float:none;
		width:100%;
		a {
			color:#000;
		}
		@media #{$medium-up}{
			float:left;
			width:50%;
		}
		@media #{$medium-up}{
			width:33.333%;
		}
	}
}


// Big Form Input

.search-form input[type=text]{
	width:80%;
	height:3.9rem ;
	color:#333 !important;
	font-size:rem-calc(18);
	display: inline-block;
	padding:rem-calc( 0 15);
	border-radius:rem-calc(10);
	background-color: rgba(255, 255, 255, 1);
}
button.search-button {
	font-size:rem-calc(23);
	color:#000;
	height:rem-calc(62);
	text-shadow: 0px 1px 0px #000000;
	display: inline-block;
	vertical-align: top;
	border-radius:rem-calc(10);
	border: 1px solid #ffef61;
	padding:rem-calc(10 30);
	background: #fed526 linear-gradient(0deg, #fed526 0%, #fed526 51%, #fff04e 51%, #fff04e 100%);
	transition:all .5s ease;

	&:hover, &:active, &:focus{
		color: #000;
		background: linear-gradient(0deg, #fed526 0%, #fed526 51%, #fff04e 51%, #fff04e 100%);
	}
}
::-webkit-input-placeholder {
	display: none;
	font-size:0;
}
:-ms-input-placeholder {
	display: none;
	font-size:0;
}
@media #{$medium-up}{
	margin-bottom:rem-calc(80);
	input[type=text]{
		color:#333;
		width:rem-calc(430);
		margin-right:rem-calc(8);
	}
	::-webkit-input-placeholder {
		font-size:rem-calc(18);
		display: block;
		color: #666 !important;
		opacity:1;
	}
	:-ms-input-placeholder {
		font-size:rem-calc(18);
		display: block;
		color: #666 !important;
		opacity:1;
	}
}

/// Product List Template Styles

.system-tested {
 padding-top:rem-calc(20)
}

.product-images {
	border:1px solid #CCC;
	background:#EEE;
	padding:15px;
	.prod-image {
		background-position:center;
		background-repeat: no-repeat;
		height:160px;
		width:100%;
		margin-bottom:15px;
	}
	.product-header {
		text-align: center;
	}
}

.home-page{
	@media #{$large-up}{
		position: relative;
		margin-top: rem-calc(-31);
	}
}